import styles from "../CustomerHoverInfo.module.css";

const TrianglePoint = ({
  backgroundColor = "white",
  rotateTrianglePoint = "0deg",
  translateTrianglePoint = "0px 0px",
}) => {
  return (
    <div
      className={styles["triangle-point-container"]}
      style={{
        translate: translateTrianglePoint,
      }}
    >
      <div
        className={styles["triangle-point"]}
        style={{
          rotate: rotateTrianglePoint,
          backgroundColor,
        }}
      />
    </div>
  );
};

export default TrianglePoint;
