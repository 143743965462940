import TrianglePoint from "./components/TrianglePoint";
import getClassName from "./helpers/getClassName";
import useDelayUnmount from "./hooks/useDelayUnmount";

const CustomHoverInfo = ({
  show = false,
  isStopBubbling = false,
  children = null,
  className = "",
  backgroundColor = "white",
  rotateTrianglePoint = "0deg",
  translateTrianglePoint = "0px 0px",
  style = {},
}) => {
  const isUnmounted = useDelayUnmount({ isMounted: show, delay: 200 });

  const classNameToUse = getClassName({ show, className });

  if (isUnmounted) return null;

  const handleClick = (event) => {
    isStopBubbling && event.stopPropagation();
  };

  return (
    <div
      className={classNameToUse}
      style={{ ...style, backgroundColor }}
      onClick={handleClick}
    >
      {children}
      <TrianglePoint
        backgroundColor={backgroundColor}
        rotateTrianglePoint={rotateTrianglePoint}
        translateTrianglePoint={translateTrianglePoint}
      />
    </div>
  );
};

export default CustomHoverInfo;
