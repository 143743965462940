import { isEmpty, snakeCase } from "lodash";
import { eventsTracker } from "../../universalFunctions/events";
import { useRouter } from "next/router";

const useEventsTracker = (componentName, isWithQuery) => {
  const { route, query } = useRouter();
  const currentPage = snakeCase(route);

  const queryNameDecider = () => {
    if (!isWithQuery || isEmpty(query)) return "";
    const includedQueries = ["menu"];
    let queryName = "_";
    includedQueries.forEach((queryKey) => {
      if (query[queryKey]) {
        queryName += `${snakeCase(queryKey)}_${snakeCase(query[queryKey])}_`;
      }
    });
    queryName += "_";
    return queryName;
  };

  const queryName = queryNameDecider();

  const trackEvent = (event, data) => {
    const eventFullName = `${currentPage}${queryName}_${snakeCase(
      componentName
    )}_${snakeCase(event)}`;
    eventsTracker(eventFullName, data);
  };

  return trackEvent;
};

export default useEventsTracker;
