import { useRouter } from "next/router";
import React, { Fragment, useState } from "react";
import { jackColors } from "../../assets/colors";
import Menu from "./menu";
import { useSelectedMenu } from "./logics";
import { snakeCase } from "lodash";
import useEventsTracker from "../../tools/hooks/useEventsTracker";

const menusTemplate = [
  {
    number: undefined,
    icon: null,
    screen: null,
    screenName: "Placeholder",
  },
];

const TabMenu = ({
  id = null,
  queryName = "menu",
  className = "",
  menuClassName = "",
  containerClassName = "",
  menus: rawMenus = menusTemplate,
  rightMenus = [],
  style = {},
  menuStyle = {},
  rightMenuStyle = {},
  containerStyle = {},
  deleteQueries,
  componentsAboveScreen, // untuk komponen diatas screen dibawah tabmenu
  hideZero = false, // untuk hide kalo counter == 0 (defaultnya ttp ditampilin)
  lastRightside,
  setIsSeeMore = () => {},
}) => {
  const menus = rawMenus.filter((item) => item);

  const { selectedMenu, currentIndex } = useSelectedMenu({
    menus: [...menus, ...rightMenus],
    queryName,
  });
  const { screen } = selectedMenu || {};
  const { query, push } = useRouter();
  const [widths, setWidths] = useState({});

  const listener = (props, index) => {
    const { menuRef } = props;
    const { clientWidth: width, offsetLeft } = menuRef?.current || {};
    const newProps = {
      [index]: {
        width,
        offsetLeft,
      },
    };
    setWidths((prev) => ({ ...prev, ...newProps }));
  };

  const currentWidth = widths[currentIndex] || {};

  const trackEvent = useEventsTracker("TabMenu");

  return (
    <Fragment>
      <nav
        className={containerClassName}
        style={{ position: "relative", ...containerStyle }}
      >
        <ul
          id={id}
          className={className}
          style={{
            margin: "0px",
            padding: "0px",
            display: "flex",
            gap: "8px",
            borderBottom: `1px solid ${jackColors.neutral500}`,
            ...style,
          }}
        >
          {menus.map((menu, index) => {
            const isSelected = currentIndex == index;

            // rightside last tab if lastRightside === true
            const isLast = index === menus.length - 1;
            const lastStyle =
              lastRightside && isLast
                ? { marginLeft: "auto", marginRight: 32 }
                : {};

            return (
              <Menu
                key={index}
                menu={menu}
                style={{ ...menuStyle, ...lastStyle }}
                isSelected={isSelected}
                className={menuClassName}
                onClick={() => {
                  push({
                    query: {
                      ...(deleteQueries ? {} : query),
                      [queryName]: snakeCase(menu.screenName),
                    },
                  });
                  trackEvent(`to_${menu.screenName}`);
                  setIsSeeMore(false);
                }}
                listener={(props) => listener(props, index)}
                hideZero={hideZero}
              />
            );
          })}

          {rightMenus.map((menu, index) => {
            const indexToUse = index + menus.length;
            const isSelected = currentIndex == index + indexToUse;
            return (
              <Menu
                key={index}
                menu={menu}
                style={{
                  marginLeft: "auto",
                  ...rightMenuStyle,
                }}
                isSelected={isSelected}
                className={menuClassName}
                onClick={() => {
                  push({
                    query: {
                      ...(deleteQueries ? {} : query),
                      [queryName]: snakeCase(menu.screenName),
                    },
                  });
                  trackEvent(`to_${menu.screenName}`);
                }}
                listener={(props) => listener(props, indexToUse)}
              />
            );
          })}
        </ul>
        <div
          style={{
            width: currentWidth.width,
            position: "absolute",
            bottom: 0,
            height: "2px",
            background: jackColors.neutral900,
            translate: `${currentWidth.offsetLeft}px 0px`,
            transition: "ease-in",
            transitionDuration: "0.1s",
          }}
        />
      </nav>
      {!!componentsAboveScreen && componentsAboveScreen}
      {!!screen && screen}
    </Fragment>
  );
};

export default TabMenu;
