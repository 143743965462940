import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useGetAuth } from "contexts/AuthContext";
import { useRouter } from "next/router";
import { getSalus } from "pageComponents/loginPageComponents/logics";
import { useState } from "react";
import { encryptToken } from "tools/api/functions/hash";
import { useMutation } from "../../tools/api";

export const useForgotPin = (isPush) => {
  const { push } = useRouter();
  const { user } = useGetAuth();
  const [errorOTP, setIsErrorOTP] = useState(false);
  const email = user?.email;

  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const { mutation } = useMutation({
    method: "post",
    url: "/pin/otp_forgot",
    afterSuccess: (_, payload) => {
      const { otp_code } = payload;
      const encryptedOTP = encryptToken(otp_code);
      isPush && push({ pathname: "reset-pin", query: { otp: encryptedOTP } });
    },
    handleError: (error) => {
      const msg = error?.response?.data?.message;
      // if (msg == "Invalid OTP Code") return setIsErrorOTP(true);
      // if (msg == "Invalid OTP Code") return setRemainingOTPAttempts(error);
    },
  });

  const {
    mutation: preOTP,
    loading: loadingPreRequest,
    result: { mobile },
    setResult,
  } = useMutation({
    url: "/otp/pre_request_otp",
    method: "post",
    resultFormatter: (res, _, payload) => {
      const mobile = res?.data?.number;
      return { ...payload, mobile };
    },
  });

  const { mutation: reqOTP, loading: loadingRequest } = useMutation({
    url: "/otp/request_user_otp",
    method: "post",
  });

  const resetOTP = () => {
    setIsErrorOTP(false);
    setResult((p) => {
      const { mobile, ...rest } = p;
      return rest;
    });
  };

  const loading = loadingPreRequest || loadingRequest;

  const preRequestOTP = async () => {
    const { requestId: salus } = await getData();
    localStorage.setItem("salus", encryptToken(salus));
    preOTP({ email, salus });
  };

  const requestOTP = (otp_with) => {
    setIsErrorOTP(false);
    const { salus } = getSalus();
    reqOTP({ otp_with, email, salus });
  };

  const reset = (payload) => {
    setIsErrorOTP(false);
    const { salus } = getSalus();
    mutation({ ...payload, salus });
  };

  return {
    mobile,
    reset,
    preRequestOTP,
    requestOTP,
    resetOTP,
    loading,
    errorOTP,
  };
};
