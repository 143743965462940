import { Banner } from "components/Banner";
import dynamic from "next/dynamic";
import { pluralize } from "pageComponents/invoiceComponents/data/formatter";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { useForgotPin } from "../../pageComponents/resetPin/logics";
import { ButtonJack } from "../ButtonsJack/parent";
import { RightModal, useSmoothRightModal } from "../Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../Text";
import { PinInput } from "./pin";
import styles from "./styles.module.css";
import { useLanguage } from "public/locales/translationFunctions";
import { decryptToken } from "tools/api/functions/hash";

const OTPSequenceModal = dynamic(() =>
  import("pageComponents/loginPageComponents/login/otp").then(
    (mod) => mod.OTPSequenceModal
  )
);

const PINBanner = ({ numTries }) => {
  const { t } = useTranslation("common");
  const { isBahasa: isID } = useLanguage();
  return (
    <Banner
      style={{ width: 250 }}
      type="warning"
      title={`${t("Incorrect PIN")}: ${
        isID
          ? `${numTries} percobaan tersisa`
          : `${numTries} ${pluralize(numTries, "attempt")} left`
      } `}
      titleStyle={{ marginTop: 4 }}
    />
  );
};

export const PinContent = ({
  isLoading,
  onSubmit: onSubmitProps,
  onBack,
  ctaText = "Input your Jack PIN",
  isResetOnSubmit = false,
  isSuccess = false,
  isForPage = false,
  isError = false,
  setIsError,
  isErrorText,
}) => {
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const [pin, setPin] = useState("");
  const currentPinLength = pin.length;
  const { t: tCommon } = useTranslation("common");

  const pinTriesLeft = decryptToken(
    localStorage.getItem("pin-remaining-attempt") || ""
  );
  const showPINErrorBanner = !!pinTriesLeft && pinTriesLeft < 3;
  const isButtonDisabled = currentPinLength < 6;
  const onSubmit = () => {
    onSubmitProps({ pin }, "pin");
    if (isResetOnSubmit) setPin("");
  };

  const {
    reset,
    mobile,
    loading,
    preRequestOTP,
    requestOTP,
    resetOTP,
    errorOTP,
  } = useForgotPin(true);

  const handleBack = () => {
    setPin("");
    !!onBack && onBack();
  };

  const success = (
    <Fragment>
      <JackIcons
        name="checkmark-circle-2"
        fill={jackColors.neutral900}
        style={{ width: "72px", height: "72px" }}
      />
      <div className={styles["success-text-container"]}>
        <GothamMedium
          className="font24"
          style={{ color: jackColors.neutral900 }}
        >
          {tCommon("Success! ")}
        </GothamMedium>
        <GothamRegular woFontColor style={{ color: jackColors.neutral700 }}>
          {tCommon("Your PIN has been changed!")}
        </GothamRegular>
      </div>
    </Fragment>
  );

  const input = (
    <PinInput
      onChange={(pin) => {
        setPin(pin);
        setIsError && setIsError(false);
      }}
      value={pin}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isError={isError}
      setValue={setPin}
      isErrorText={isErrorText}
    />
  );

  if (isForPage) {
    return (
      <div className={styles["container"]}>
        <div className={styles["content-container"]}>
          {isSuccess ? (
            success
          ) : (
            <Fragment>
              <GothamMedium className={styles["title"]}>{ctaText}</GothamMedium>
              {input}
            </Fragment>
          )}
        </div>
        <div className={styles["bottom-control-container_on-page"]}>
          {isSuccess ? (
            <Fragment>
              <ButtonJack
                onClick={onSubmit}
                className={styles["button_on-page"]}
              >
                {tCommon("Close ")}
              </ButtonJack>
            </Fragment>
          ) : (
            <Fragment>
              <ButtonJack
                isLoading={isLoading}
                disabled={isButtonDisabled}
                onClick={onSubmit}
                className={styles["button_on-page"]}
              >
                {tCommon("Confirm")}
              </ButtonJack>
              <ButtonJack
                onClick={handleBack}
                className={styles["button_on-page"]}
                type="outline"
                disabled={isLoading}
              >
                {tCommon("Cancel")}
              </ButtonJack>
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <JackIcons name="jack-horizontal" />
      <div className={styles["content-container"]}>
        {isSuccess ? (
          success
        ) : (
          <Fragment>
            <GothamMedium className={styles["title"]}>
              {" "}
              {tLocalTransfer(ctaText)}
            </GothamMedium>
            {input}
            {showPINErrorBanner ? <PINBanner numTries={pinTriesLeft} /> : null}
            <GothamMedium
              className={styles["underline-medium"]}
              onClick={preRequestOTP}
            >
              {tCommon("Forgot your PIN?")}
            </GothamMedium>
          </Fragment>
        )}
      </div>
      <div className={styles["bottom-control-container"]}>
        {isSuccess ? (
          <ButtonJack onClick={onSubmit} className={styles["button"]}>
            {tCommon("Close")}
          </ButtonJack>
        ) : (
          <Fragment>
            <ButtonJack
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={onSubmit}
              className={styles["button"]}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
            >
              {tCommon("Confirm")}
            </ButtonJack>
            {onBack && (
              <ButtonJack
                onClick={handleBack}
                className={styles["button"]}
                type="outline"
                disabled={isLoading}
              >
                {tCommon("Back")}
              </ButtonJack>
            )}
          </Fragment>
        )}
      </div>
      {mobile && (
        <OTPSequenceModal
          isOpen={Boolean(mobile)}
          toggle={resetOTP}
          isLoading={loading}
          isErrorOTP={errorOTP}
          handleRequestOTP={requestOTP}
          mobile={mobile}
          onClickSubmitOTP={(otp_code) => reset({ otp_code })}
        />
      )}
    </div>
  );
};

const PINModal = ({
  isOpen,
  toggle,
  isLoading,
  onSubmit,
  onBack,
  ctaText: ctaTextRaw,
  isResetOnSubmit = false,
  isSuccess = false,
  isError,
  setIsError,
  woClose = false,
  isErrorText = "",
}) => {
  const { t } = useTranslation("settings-jack");
  const { isBahasa: isID } = useLanguage();
  const { toToggle, toListener } = useSmoothRightModal();
  const ctaText = (function () {
    if (Boolean(ctaTextRaw)) return ctaTextRaw;

    return isID ? "Masukkan PIN Anda" : "Input your Jack PIN";
  })();
  const ctaValue = t(ctaText) || t("Enter your Jack PIN");

  return (
    <RightModal
      listener={toListener}
      isOpen={isOpen}
      toggle={() => {
        setIsError && setIsError(false);
        toggle();
      }}
      woClose={woClose}
      array={[
        <PinContent
          isLoading={isLoading}
          onSubmit={(values) => onSubmit(values, toToggle)}
          onBack={onBack}
          ctaText={ctaValue}
          isResetOnSubmit={isResetOnSubmit}
          isSuccess={isSuccess}
          isError={isError}
          setIsError={setIsError}
          isErrorText={isErrorText}
        />,
      ]}
      width={[420]}
    />
  );
};

export default PINModal;
