import { useEffect, useState } from "react";

const useDelayUnmount = ({ isMounted = false, delay = 200 }) => {
  const [isUnmounted, setIsUnmounted] = useState(true);

  useEffect(() => {
    if (isMounted) return setIsUnmounted(false);

    return setTimeout(() => setIsUnmounted(true), delay);
  }, [isMounted]);

  return isUnmounted;
};

export default useDelayUnmount;
