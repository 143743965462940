import { snakeCase } from "lodash";
import { useRouter } from "next/router";

export const useSelectedMenu = ({ queryName = "menu", menus = [] }) => {
  const { query } = useRouter();

  const filteredMenus = menus.filter((item) => item);

  const selectedMenu =
    filteredMenus.find(
      ({ screenName }) => snakeCase(screenName) === snakeCase(query[queryName])
    ) ?? filteredMenus[0];

  const currentIndex = filteredMenus
    .map(({ screenName }, index) => {
      if (snakeCase(screenName) == snakeCase(selectedMenu.screenName))
        return index;
    })
    .filter((item) => item !== undefined)[0];

  return { selectedMenu, currentIndex };
};
