import styles from "../CustomerHoverInfo.module.css";

const getClassName = ({ show = false, className = "" }) => {
  const customClassName = className;
  const defaultClassName = styles["hover-info"];
  const animationClassName = show ? styles["fade-in"] : styles["fade-out"];

  const classNameToUse = `${defaultClassName} ${animationClassName} ${customClassName}`;

  return classNameToUse;
};

export default getClassName;
