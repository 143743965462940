import { times } from "lodash";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { GothamMedium, GothamRegular } from "../Text";
import { unformatCurrency } from "@tools";

export const PinInput = ({
  isLoading,
  onChange: onChangeProps,
  value,
  onSubmit,
  isError,
  setValue,
  isErrorText,
  length = 6,
  isPassword = true,
}) => {
  const smarterError = isErrorText && !value;
  const pinInputRef = useRef(null);

  useEffect(() => {
    if (value) return;
    setTimeout(() => pinInputRef?.current?.focus(), 400);
  }, [value, !!pinInputRef?.current]);

  useEffect(() => {
    if (isError) {
      setValue("");
    }
  }, [isError, onChangeProps]);

  const currentPinLength = value?.length;

  const onChange = (event) => {
    const currentValue = (event?.target?.value || "").replace(/\D/g, "");

    const keyValue = event?.nativeEvent?.inputType;

    if (keyValue === "deleteContentBackward") {
      if (currentPinLength === 0) return;
      const newPin = value?.slice(0, -1);
      onChangeProps(newPin);
    } else if (keyValue === "Enter") {
      onSubmit();
    } else {
      const acceptedCurrentValue = currentValue.slice(0, length);

      onChangeProps(acceptedCurrentValue);
    }
  };

  const onKeyDown = (event) => {
    const keyValue = event?.key;

    if (keyValue === "Enter") {
      if (currentPinLength !== length) return;
      if (isLoading) return;
      onSubmit();
    }
  };

  return (
    <div>
      <input
        className={styles["pin-input"]}
        onChange={onChange}
        onKeyDown={(event) => onKeyDown(event)}
        tabIndex={0}
        ref={pinInputRef}
        value={value}
      />
      <div className={styles["pin-item-container"]}>
        {times(length, (index) => {
          const pinClassNameDecider = (index, isError) => {
            const isPreviousPin = index < currentPinLength - 1;
            const isCurrentPin = index === currentPinLength - 1;
            const isBlinking = index === currentPinLength;
            const isNextPin = index > currentPinLength - 1;

            if (isError) return styles["error-pin"];
            if (isPreviousPin || isCurrentPin) return styles["previous-pin"];
            // if (isCurrentPin) return styles["current-pin"];
            if (isBlinking) return styles["blinking-pin"];
            if (isNextPin) return styles["next-pin"];
          };

          const pinClassName = pinClassNameDecider(
            index,
            isError || smarterError
          );

          const char = value?.charAt(index);

          const charDecider = () => {
            if (!char) return null;
            if (isPassword) return <div className={styles["circle"]} />;
            return <GothamMedium className="font20">{char}</GothamMedium>;
          };

          return (
            <div
              key={index}
              className={`${styles["pin-item"]} ${pinClassName}`}
            >
              {charDecider()}
            </div>
          );
        })}
      </div>
      {(isError || smarterError) && (
        <GothamRegular className={styles["error-text"]}>
          {isErrorText || "Incorrect PIN. Please try again."}
        </GothamRegular>
      )}
    </div>
  );
};
